import React, { useState, useEffect } from 'react';
import { getSpecialCategory, getOffersBanner, getCategoryHome, } from '../services/services';
import * as language from '../constants/languages'
import HomeBannerCarousal from '../components/HomeBannerCorousal';
import HeaderRoot from '../components/HeaderRoot';
import CategoryCard from '../components/CategoryCard';
import Viewall from '../assets/svg/viewall.svg'
import { useNavigate } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import Snackbar from '@mui/material/Snackbar';
import FeaturedProductListComponent from '../components/FeaturedProductListComponent'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
export default function HomePage() {
    const [isLoadingSpecailCate, setisLoadingSpecailCate] = useState(true)
    const [isLoadingOfferBanner, setisLoadingOfferBanner] = useState(true)
    const [isOffer, setisOffer] = useState(false)
    const [specialCateData, setspecialCateData] = useState([])
    const [offersData, setoffersData] = useState([])
    const [categoryData, setcategoryData] = useState([])
    const [categoryLoading, setcategoryLoading] = useState(true)
    const navigate = useNavigate();
    const [snackopen, setsnackopen] = useState(false)
    const [message, setmessage] = useState('')
    
    useEffect(() => {
        getOffersBannerHome();
        getCategoryData();
        getSpecialCateData();
    }, [])

    async function getOffersBannerHome() {
        const branchId = localStorage.getItem('storeId');
        const token = localStorage.getItem('access_token');
        await getOffersBanner(language.companyidValue, branchId, token).then((offersData) => {
            if (offersData.status === "success" && offersData.data.length != 0) {

                setisLoadingOfferBanner(false)
                setisOffer(true)
                setoffersData(offersData.data)

            } else {

                setisLoadingOfferBanner(false)
                setisOffer(false)
                setoffersData([])

            }
        })
    }

    async function getCategoryData() {
        const branchId = localStorage.getItem('storeId');
        await getCategoryHome(language.companyidValue, branchId).then((data) => {
            if (data.status === "success") {

                setcategoryLoading(false)
                setcategoryData(data.data)

            } else {

                setcategoryLoading(false)
                setcategoryData([])

            }
        })
            .catch((error) => { })
    }

    async function getSpecialCateData() {

        const branchId = localStorage.getItem('storeId');
        await getSpecialCategory(language.companyidValue, branchId).then((data) => {
            // debugger
            if (data.status == "success") {

                setisLoadingSpecailCate(false)
                setspecialCateData(data.data)

            } else {

                setisLoadingSpecailCate(false)
                setspecialCateData([])
            }
        })


            .catch((error) => { })
    }


    // render special characters
    function renderSpecialCategory(index, type, items) {

        if (index % 2 == 0) {
            if (type === "Category") {
                return (
                    <div key={index}>
                        {
                            (items == null || items.length == 0) ?
                                null
                                :
                                // <SpecialCategoryComponent
                                //     data={items}

                                // />
                                <></>
                        }
                    </div>

                )
            } else {
                return (
                    <div key={index}>
                        {
                            (items == null || items.length == 0) ?
                                null
                                :
                                <FeaturedProductListComponent
                                    data={items}
                                    navigate={navigate}
                                />
                        }
                    </div>

                )
            }

        } else {
            if (type === "Category") {
                return (
                    <div key={index}>
                        {
                            (items == null || items.length == 0) ?
                                null
                                :
                                // <SpecialCategoryComponent
                                //     data={items}

                                // />
                                <></>
                        }
                    </div>

                )
            } else {
                return (
                    <div key={index}>
                        {
                            (items == null || items.length == 0) ?
                                null
                                :
                                <FeaturedProductListComponent
                                    data={items}
                                    navigate={navigate}
                                />

                        }
                    </div>
                )
            }
        }
    }
    const onSnackClose = () => {
        setsnackopen(false)

    }
    const action = (
        <>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setsnackopen(false)}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );
    return (
        <div >
            <HeaderRoot opensnack={(msg) => {
                setsnackopen(true)
                setmessage(msg)
            }} />
            {
                isLoadingOfferBanner == true ? <></> :
                    <div>
                        {
                            isOffer ?
                                <HomeBannerCarousal
                                    data={offersData}

                                />
                                : null
                        }
                    </div>
            }
            <div style={{ textAlign: 'center', marginTop: '3rem' }}>
                <h4 style={{ fontFamily: 'Poppins-Regular', }}>Shop by Categories</h4>
            </div>
            <div style={{ marginInline:'auto' }}>
                <CategoryCard
                    categoryData={categoryData}
                />
            </div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center',cursor:'pointer', marginTop:15 }} onClick={() => navigate('Categories')}>
                <p style={{
                    fontFamily: 'Poppins-Regular',
                    textAlign: 'center',
                    marginRight: 5
                }}>{language.viewAll}

                </p>
                <img  src={Viewall} height={23} width={15}></img>
            </div>
            <div style={{marginTop:'3rem'}}>
                {
                    isLoadingSpecailCate == true ?
                        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Spinner animation="border" role="status" />
                        </div>
                        :
                        <>
                            {
                                specialCateData.map((splCateData, index) => (
                                    <div key={index}>
                                        <h4 style={{ textAlign: 'center', fontFamily: 'Poppins-Regular' }}>{splCateData.specialcategoryName}</h4>
                                        {renderSpecialCategory(index, splCateData.type, splCateData.specialCategoryItems)}
                                    </div>
                                ))
                            }
                        </>
                }
            </div>
            <Snackbar
                open={snackopen}
                autoHideDuration={2000}
                onClose={onSnackClose}
                message={message}
                action={action}
            />
        </div>
    )
}