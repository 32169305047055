import React, { useEffect, useState, useContext } from 'react';
import * as language from '../constants/languages';
import DrawerIcon from '../assets/svg/header/drawericon.svg';
import WhatsApp from '../assets/svg/header/whatsapp.svg';
import { getSupportData } from '../services/services';
import '../styles/HeaderMain.css'
import { AuthContext } from '../pages/RootPage';
import { isMobile } from 'react-device-detect';
import RightArrow from '../assets/svg/right.svg'
import { useNavigate } from 'react-router-dom';
export default function HeaderMain() {
    const navigate = useNavigate()
    const [whatsAppNo, setwhatsAppNo] = useState('')
    const [supportNumber, setsupportNumber] = useState('')
    const { toggleDrawer } = useContext(AuthContext);
    useEffect(() => {
        getCompanyInfo();
        getSupportInfoData();
        return () => {
            localStorage.removeItem('cateFilter');
            localStorage.removeItem('brandFilter');
            localStorage.removeItem('ratingFilter');
        }
    }, [])


    //get sotre name
    async function getCompanyInfo() {

        const value = localStorage.getItem('companyInfo')
        if (value === null || value === undefined) {
            setwhatsAppNo("")
        } else {
            let companyInfo = JSON.parse(value);
            setwhatsAppNo(companyInfo.whatsAppNo)
        }

    }

    async function getSupportInfoData() {

        await getSupportData(language.companyidValue).then((supportData) => {
            if (supportData.status === 'success') {
                setsupportNumber(supportData.data.contactNos)
            }
        })
    }
    async function callNow() {
        window.open(`tel:${supportNumber}`)
    }

    async function openWhatsapp() {
        if (isMobile) {
            window.open('whatsapp://send?phone=' + whatsAppNo)
        }
        else {
            window.open(`https://web.whatsapp.com/send?phone=${whatsAppNo}`)
        }
    }

    return (

        <div className='containerHeaderMain' >
            <div className='drawer-icon'>
                <img src={DrawerIcon} style={{ width: 20, height: 20 }} onClick={() => toggleDrawer()} />
            </div>

            <div className='routeWrapper'>
                <p className='route-names' onClick={() => navigate('/')}>Home</p>
                <img src={RightArrow} />
                <p  style={{fontFamily: 'Poppins-Regular', fontSize: '20px', flexWrap: 'nowrap',margin:0}}onClick={() => navigate('/Categories')}>Categories</p>
            </div>

            <div className='whatsapp-header' onClick={() => openWhatsapp()} >

                <img src={WhatsApp} style={{ width: 45, height: 45, }} />
            </div>

            <div className="CallNowCommon" onClick={() => callNow()}>
                <p style={{ margin: 0, color: "#fff" }}>Call Now</p>
            </div>
        </div>

    )
}