import { useState, useEffect, } from 'react';
import { getCompanyInfo } from './services/services';
import * as language from '../src/constants/languages'
import StoreChoose from './pages/StoreChoose';
import { router } from './config/router'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SpinnerLoader from './components/SpinnerLoader';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { RouterProvider } from 'react-router-dom';


function App() {
  const [storeChoosed, setstoreChoosed] = useState()

  const readDataevent = (data) => {
    storeSelected(data.detail)

  }

  // storeSelected
  async function storeSelected(store) {
    if (store == null) {
      localStorage.removeItem('storeChooseFlag')
      await setstoreChoosed(store)

    } else {
      localStorage.setItem('storeChooseFlag', store)
      await setstoreChoosed(store)

    }

  }

  // get the branch data
  async function getCompanyInfoIndex() {
    await getCompanyInfo(language.companyidValue).then((response) => {
      if (response.status === 'success') {
        localStorage.setItem('companyInfo', JSON.stringify(response.data));
      }
      checkStoreChoosed();

    })
      .catch((error) => {
        // console.log(error, 'company info error');
        checkStoreChoosed();
      })
  }

  // check if store choosed
  async function checkStoreChoosed() {

    const value = localStorage.getItem('storeChooseFlag')
    setstoreChoosed(value)

  }

  // check if store choosed
  async function checkCompanyInfo() {
    const value = localStorage.getItem('companyInfo')
    if (value === null || value === undefined) {
      //no value set in storage
      getCompanyInfoIndex();
      // this.checkStoreChoosed();
    } else {
      //this.checkStoreChoosed();
      getCompanyInfoIndex();
    }

  }
  useEffect(() => {


    checkCompanyInfo();
    window.addEventListener('storeSelected', readDataevent)
    // checkLogin();

    return () => {
      window.removeEventListener('storeSelected', readDataevent);
    }
  }, [])





  return (
    <div >
      
      {
        storeChoosed != null ?
          <div >

            <div className='routerContainer'>
              <RouterProvider router={router} />
            </div>

          </div>
          :


          <div className='SpinnerContainer'>
            <StoreChoose />

            <SpinnerLoader />


          </div>

      }
    </div>
  );
}

export default App;
